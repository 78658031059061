.container {
    // width: 100%;
    // text-align: center;
    min-height: 100px;
    // position: absolute;
    // bottom: 0;
    .labelContainer {
        .label {
            position: relative;
            top: -4px;
            font-size: 15px;
            font-weight: normal;
            color: #7f7e7a;
            &.labelRight {
                text-align: right;
                color: #679B8F;
            }
            @media only screen and (min-width: 1024px) {
                font-size: 26px;
            }
        }
    }
    h3 {
        text-align: center;
    }
    .showBar {
        h3 {
            text-align: left;
        }
    }
    .chartContainer {
        margin-top: 14px;
    }
    .formulaDescription {
        font-size: 12px;
        margin-top: -20px;
    }
}