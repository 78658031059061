.container {
    margin-top: 21px;    
}
.switch {
    position: relative;
    display: inline-block;
    width: 42px;
    height: 34px;
    span.label {
        position: relative;
        top: -7px;
        left: 56px;
        color: #7C7B78;
        font-size: 16px;
        font-weight: normal;
    }
  }
  
  .switch input { 
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .slider {
    height: 12px;
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #DEDFD9;
    -webkit-transition: .4s;
    transition: .4s;
    
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 24px;
    width: 24px;
    left: 0;
    bottom: -6px;
    background-color: white;
    border: 1px solid #000;
    transition: 0.15s;
  }
  
  input:checked + .slider {
    background-color: #000;
  }
  
  input:checked + .slider:before {
    transform: translateX(18px);
  }
  
  .slider.round {
    border-radius: 24px;
  }
  
  .slider.round:before {
    border-radius: 50%;
  }