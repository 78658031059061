.container {
    width: 100%;
}
label {
    width: 100%;
    text-align: left;
    font-size: 12px;
    font-weight: bold;
    color: #000;
    
}
.select {
    display: block;
    font-size: 16px;
    font-family: "Helvetica Neue", "Helvetica", "sans-serif";
    font-weight: normal;
    letter-spacing: 0.4px;
    color: #565A5D;
    line-height: 1.3;
    padding: .5em 1.4em .5em .8em;
    width: 100%;
    max-width: 100%;
    box-sizing: border-box;
    margin: 0;
    margin-top: 7px;
    border: 1px solid #DDDDD9;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background-color: #fff;
    // background-image: url(data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3…c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E), linear-gradient(to bottom, #ffffff 0%,#e5e5e5 100%);
    // background-repeat: no-repeat, repeat;
    background-position: right .7em top 50%, 0 0;
    // background-size: .65em auto, 100%;
    background-image: url('./img/icon-arrow-down.svg');
    background-repeat: no-repeat;
    background-size: 22px 16px;
    outline: none;
    outline: 0;
}
select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #000;
}
