.inputPane {
    // width: 100%;
    // min-height: 100%;
    // position: absolute;
    // top: 0;
    // right: 0;
    // background-color: aliceblue;
    background-color: #ffffff;;
    margin-top: 160px;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    flex: 4;
    opacity: 0;
    visibility: hidden;
    transition: all 0.2s;
    padding-left: 37px;
    padding-right: 37px;
    &.active {
        opacity: 1;
        visibility: visible;
    }
    &.noScroll {
        overflow-y: hidden;
    }
    
    @media only screen and (min-width: 576px) {
    //    width: 400px;
   }

   

   @media only screen and (min-width: 768px) {
       flex: 1;
       margin-left: -50%;
       z-index: 1;
   }

  

}

.bottomSpace {
    height: 140px;
}
.btnCloseTop {
    float: right;
    position: relative;
    top: 28px;
    right: -15px;
}
.btnCloseBottom {
    text-align: center;
    margin-top: 30px;
}
.bottomNav {
    // width: 100%;
    height: 65px;
    background: #000;
    color: #FFF;
    position: sticky;
    bottom: 0;
    text-align: center;
}
.btnSaveReport {
    border: 1px solid #fff;
    width: 50%;
    margin: 0 auto;
    height: 35px;
    text-transform: uppercase;
    position: relative;
    top: 16px;
    padding-top: 4px;
    cursor: pointer;
}