.container {
    width: 100%;
    margin-top: 7px;
    background: #FFF;
    display: flex;
    flex-wrap: wrap;
}
.card {
    width: calc(20% - 8px);
    margin-right: 10px;
    &:nth-of-type(5n+5) {
        margin-right: 0;
    }
    margin-bottom: 22px;
    padding: 2px;
    border: 1px solid #FFF;
    .imgContainer {
        position: relative;
        width: 100%;
        cursor: pointer;
        &:after {
            content: "";
            display: block;
            padding-bottom: 100%;
        }
        img {
            position: absolute;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }
    .label {
        display: block;
        text-align: center;
        padding-top: 3px;
        padding-bottom: 2px;
        color: #5C5C5B;
        font-size: 12px;
        border-color: #DEDFD9;
        border-width: 0 1px 1px 1px;
        border-style: solid;
    }
    &.selected {
        border: 1px solid #000;
    }
}
