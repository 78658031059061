html, body {
    height: 100%;
    background: #faf8f7;
}
#root {
    height: 100vh;
    display: flex;
}
.App {
    display: flex;
    flex-direction: column;
    flex: 1;
    @media only screen and (min-width: 768px) {
       flex-direction: row;
    }
}
