.container {
    // flex: 1;
    height: 48px;
    background: none;
    color: rgba(0, 0, 0, 0.7);
    text-align: center;
    cursor: pointer;
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 1.8px;
    border: none;
    white-space: nowrap;
    border-bottom: 2px solid rgba(0, 0, 0, 0);
    padding: 0;
    &.active {
        border-bottom: 2px solid #000;
    }
    &.isSquare {
        width: 48px;
        line-height: 48px;
        
        &.active {
            color: rgba(0, 0, 0, 1.0);
            border-bottom: 2px solid #000;
            background: #FFF;
        }
        &.disabled {
            background: none;
            border-bottom: 1px solid #DFDFDF;
            cursor: default;
        }
    }
    &.isCircular {
        width: 48px;
        border-radius: 24px;
        border: 1px solid #DFDFDF;
        background: #ffffff;
    }
    &.isRounded {
        border-radius: 24px;        
        background: white;
        color: #000;
        padding-left: 18px;
        padding-right: 18px;
        &.hasIcon {
            &.hasLabel {
                padding-right: 20px;
                img, svg {
                    margin-right: 10px;
                    top: 2px;
                }
                span {
                    position: relative;
                    top: -2px;
                }
            }        
        }
        &.isDashed {
            border-style: dashed;
            background: none;
        }
        &.isDotted {
            border: 2px dotted #777772;
            background: none;
            opacity: 0.5;
        }
        &.active {
            border: 2px solid #000;
        }
    }
    &.hasBorders {
        border: 1px solid #DFDFDF;
        &.isSquare {
            border-right: none;
            &:last-of-type {
                border-right: 1px solid #DFDFDF;
            }
        }
    }
    &.hasStrongBorders {
        border: 1px solid #000;
    }
    &.hasDoubleBorders {
        border: 3px double #000;
    }
    &.hasIcon {
        svg, img {
            width: 17px;
            height: 17px;
            vertical-align: initial;
            position: relative;
            top: 3px;
            &.hasLabel {
                left: 12px;
            }
        }
        &.isSquare {
            svg, img {
                top: 2px;
                left: 0;
            }
        }
        &.isCircular {
            svg, img {
                left: 0;
            }
        }
        &.iconFirst {
            margin-right: 30px;
            svg, img {
                top: 4px;
                left: 0;
                margin-right: 13px;
            }
            &.isRounded {
                padding-left: 3px;
                svg, img {
                    left: 12px;
                    margin-right: 22px;
                }
            }
        } 
    }
    &.linkStyle {
        color: #366bbc;
        font-weight: bold;
        font-size: 16px;
        text-transform: none;
        letter-spacing: normal;
    }
    
}
