.container {
    width: 100%;
    position: relative;
    -webkit-user-select: none;
}
.top {
    display: flex;
    width: 100%;
    text-align: left;
    :first-child {
        flex: 3
    }
    >* {
        flex: 1;
    }
    h4 {
        padding-top: 19px;
    }
    .inputtextContainer {
        span {
            color: #67696D;
        }
    }
}



.slider {
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 100%;
    outline: none;
    background: transparent;
    outline: none;
    border: 0;
}
.slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background: #000;
    cursor: pointer;
    margin-top: -7px;
}
.slider::-webkit-slider-runnable-track {
    background: #FFF;
    border: 1px solid #E6E6E3;
    border-radius: 6px;
    height: 12px;
}
.slider::-moz-range-thumb {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background: #000;
    cursor: pointer;
    margin-top: 0;
    border: none;
}
.slider::-moz-range-track {
    background: #FFF;
    border: 1px solid #E6E6E3;
    border-radius: 6px;
    height: 12px;
}
.slider::-moz-range-progress {
    background: #EAEFF8;
    border: 1px solid #E6E6E3;
    border-radius: 6px;
    height: 12px;
}
.slider::-moz-focus-outer {
    border: 0;
}
// .slider::-ms-thumb {
//     border: none;
//     height: 24px;
//     width: 24px;
//     border-radius: 50%;
//     background: #000;
//     margin-top: 0;
//     cursor: pointer;
// }
// .slider::-ms-track {
//     width: 100%;
//     height: 12px;
//     background: #FFF;
//     border-color: transparent;
//     border-width: 6px 0;
//     color: transparent;
// }
// .slider::-ms-fill-lower {
//     background: #777;
//     border-radius: 10px;
// }
// .slider::-ms-fill-upper {
//     background: #D7D6D5;
//     border-radius: 10px;
// }

// .slider:focus::-ms-fill-lower {
//     background: #777;
// }
// .slider:focus::-ms-fill-upper {
//     background: #D7D6D5;
// }

