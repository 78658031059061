.container {
    width: 100%;
    margin-top: 80px;
    padding-right: 100px;
    padding-left: 100px;
    padding-top: 66px;
    background: #faf8f7;
    text-align: center;
    >:last-child {
        margin-bottom: 70px;
    }

    .radioContainer {
        width: 55vw;
        max-width: 567px;
        position: relative;
        margin: 15px auto 26px auto;

        .radioWrapper {
            height: 48px;
            &.background {
                background: #FFF;
                border-top: 1px solid #DDDDD9;
                border-right: 1px solid #DDDDD9;
                border-left: 1px solid #DDDDD9;
                &:last-of-type {
                    border-bottom: 1px solid #DDDDD9;
                }
            }
        }
        .inputFilename {
            position: absolute;
            top: 0;
            left: 56px;
            height: 48px;
            right: 0;
            margin-top: 0 auto;
            width: calc(100% - 56px);
            padding-left: 18px;
            border: none;
            border-bottom: 2px solid #000;
        }
    }
}