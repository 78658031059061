.container {
    flex: 1;
    width: 100%;
    background: none;
    color: rgba(0, 0, 0, 0.7);
    text-align: center;
    cursor: pointer;
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 1.8px;
    border: none;
    white-space: nowrap;
    border-bottom: 2px solid rgba(0, 0, 0, 0);
    padding: 0;
    position: relative;
    top: 5px;
    svg, img {
        width: 64px;
        height: 64px;
        vertical-align: initial;
        position: relative;
        top: 3px;
    }
    span {
        display: block;
        position: relative;
        top: 10px;
    }
    &.compact {
        top: 25px;
        svg, img {
            width: 48px;
            height: 48px;
        }
    }
}
