
body {
  margin: 0;
  padding: 0;
  font-family: "Helvetica Neue", "Helvetica", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #5C5C5B;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

p.subtitle {
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 1.2px;
}


h1 {
  color: #000;
  line-height: 1.1;
}
h2 {
  color: #000;
  font-size: 28px;
  font-weight: bold;
  margin-bottom: 12px;
}

h3 {
  font-weight: bold;
  font-size: 18px;
  color: #000;
}
h4 {
  font-weight: bold;
  font-size: 12px;
  color: #000;
}
h5 {
  font-weight: bold;
  font-size: 16px;
  color: #5C5C5B;
}
ul {
  color: #000;
}

/* Bootstrap overrides */
.modal-header {
  border-bottom: none;
}
.modal-footer {
  border-top: none;
}
.modal-footer > :not(:first-child) {
  margin-left: 20px;
}