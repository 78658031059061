.container {
    width: 100%;
    form {
        height: 48px;
        text-align: right;
    }
    .inputText {
        width: 100%;
        height: 40px;
        margin-top: 4px;
        padding-left: 18px;
        padding-right: 0;
        border: none;
        background: none;
        visibility: hidden;
        position: relative;
        top: -40px;
        text-align: right;
        // border: 1px solid #DDDDD9;
        // text-align: center;
        &.visible {
            visibility: visible
        }
    }
    .button {
        
        height: 40px;
        
        // padding-left: 54px;
        display: inline-block;
        padding-left: 18px;
        padding-right: 0;
        position: relative;
        top: -40px;
        border: none;
        background: none;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        // color: rgba(0, 0, 0, 0);
        // text-align: center;
        // width: 100%;
        // margin: 0 auto;
        position: relative;
        top: 4px;
        img {
            width: 20px;
            margin-top: -3px;
            margin-right: 8px;
        }
        &.hidden {
            visibility: hidden;
        }
    }
}
