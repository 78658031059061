.container {
    margin: 0 auto;
    width: 100%;
    height: 100%;
    margin-top: -10%;
    opacity: 0.0;
    transition: all 0.4s;
    position: relative;
    overflow: hidden;
    &.mounted {
        opacity: 1.0;
    }
    canvas {
        width: 100% !important;
    }
    &.fullWidth {
        margin-top: 0;
    }
}
.wallText {
    width: 400px;
    height: 400px;
}
.overlay {
    margin-top: -10%;
    position: absolute;
    top: 0;
    visibility: visible;
    opacity: 1;
    transition: opacity 0.4s;
    .overlayBack, .overlayRight, .overlayFront, .overlayLeft, .overlayCenter, .overlayCorner, .overlayPlay {
        position: absolute;
        top: 0;
        left: 0;
        .wallBtn {
            position: relative;
            z-index: 1;
        }
        .nameLabel {
            position: absolute;
            top: 44px;
            left: 50%;
            transform: translateX(-50%);
            color: #FFF;
            background-color: #8F8F8F;
            border-radius: 10rem;
            font-weight: normal;
            display: block;
            line-height: 1;
            text-align: center;
            white-space: nowrap;
            font-size: 10px;
            padding: 8px;
            padding-right: 28px;
            padding-left: 28px;
            @media only screen and (min-width: 1024px) {
                font-size: 17px;
            }
        }
    }
}
.hidden {
    visibility: hidden;
    opacity: 0;
}