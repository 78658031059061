.container {
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    flex: 1;
    display: none;
    background-color: #faf8f7;
    width: 50%;
    height: 100%;
    display: block;
    position: absolute;
    left: 0;
    overflow: hidden;
    // transition: width 0.3s, margin 0.3s;
    transition: all 0.4s;
    border-right: 1px solid #DEDFD9;
    &:nth-of-type(2) {
        left: 50%;
    }
    &:nth-of-type(3) {
        left: 100%;
    }
    &:nth-of-type(4) {
        left: 150%;
    }
    .headerWrapper {
        position: relative;
        z-index: 1;
        position: absolute;
        left: 50%;
        top: 20px;
        transform: translateX(-50%);
        h2 {
            margin-top: 7px;
        }
        // height: 76px;
        // text-align: center;
        // position: relative;
        // z-index: 1;
    }
    .header {
        // position: absolute;
        // bottom: 0;
        // width: 100%;
        // * {
        //     margin: 0;
        // }
        // h1 {
        //     font-size: 28px;
        // }
    }
    .btnFullscreen {
        margin-left: 100px;
    }
    .charts {
        width: 100%;
        display: flex;
        text-align: center;
        position: absolute;
        bottom: 30px;
        padding-left: 55px;
        padding-right: 55px;
        .leftChart {
            flex: 1;
            padding-right: 19px;
        }
        .rightChart {
            flex: 1;
            padding-left: 19px;
        }
    }
    @media only screen and (min-width: 768px) {
        // display: initial
        // display: inline-block;
        display: block;
    }
    &.fullWidth {
        width: 100%;
        &:nth-of-type(2) {
            left: 100%;
        }
        &:nth-of-type(3) {
            left: 200%;
        }
        &:nth-of-type(4) {
            left: 300%;
        }
        .charts {
            // visibility: hidden;
            opacity: 0;
            transition: opacity 0.2s;
        }
    }
}
