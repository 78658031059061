.container {
    width: 100%;
    margin-top: 80px;
    padding-right: 100px;
    padding-left: 100px;
    padding-top: 66px;
    .glossaryItems {
        display: flex;
        margin-top: 24px;
        margin-bottom: 80px;
        .glossaryCol {
            flex: 1;
            margin-right: 32px;
            &:last-of-type {
                margin-right: 0;
            }
        }
    }

    .tables {
        h3 {
            margin-top: 38px;
        }
        padding-bottom: 60px;
    }

    table {
        width: 100%;
        background: #FFF;
        border: 1px solid #DDDDD9;
        margin-top: 24px;
        margin-bottom: 60px;
        &:last-of-type {
            margin-bottom: 80px;
        }
        tr {
            border-bottom: 1px solid #DDDDD9;
        }
        tbody {
            tr {
                &:last-of-type {
                    border: none;
                }
            }
        }
        th, td {
            text-align: center;
            vertical-align: top;
            padding: 20px 25px 20px 25px;
            border-right: 1px solid #DDDDD9;
            &:first-of-type {
                text-align: left;
            }
            &:last-of-type {
                border: none;
            }
        }
    }
}