.container {
    width: 100%;
    opacity: 0.0;
    transition: opacity 0.4s;
    position: relative;
    &.mounted {
        opacity: 1.0;
    } 

    &.compact {
        position: relative;
        height: 105px;
        margin-top: -15px;
        .background {
            position: absolute;
            width: 100%;
        }
    }

}
