/* generated by https://loading.io/ */
#backdrop {
    overflow: clip;
    position: fixed;
    height: 100vh;
    width: 100%;
    top: 0;
    left: 0;
    background: rgba(255, 255, 255, 0.8);
    z-index: 9999;
  }
  #backdrop__icon {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 60px;
    height: 60px;
  }
  #backdrop__text {
    display: block;
    margin-top: .5em;
    font-weight: bold;
    text-align: center;
    text-indent: .1em;
    color: #696864;
  }
  
  // Spinner
  @keyframes ldio-6cciijfifcw {
    0% { transform: translate(-50%,-50%) rotate(0deg); }
    100% { transform: translate(-50%,-50%) rotate(360deg); }
  }
  .ldio-6cciijfifcw div {
    position: absolute;
    width: 100%;
    height: 100%;
    border: 6px solid #7b849a;
    border-top-color: transparent;
    border-radius: 50%;
  }
  .ldio-6cciijfifcw div {
    animation: ldio-6cciijfifcw 1s linear infinite;
    top: 50%;
    left: 50%;
  }
  .loadingio-spinner-rolling-qoc7v6b3gdf {
    width: 100%;
    height: 100%;
    display: inline-block;
    overflow: hidden;
    background: none;
  }
  .ldio-6cciijfifcw {
    width: 100%;
    height: 100%;
    position: relative;
    transform: translateZ(0) scale(1);
    transform-origin: 0 0; /* see note above */
  }
  .ldio-6cciijfifcw div { box-sizing: border-box; }
  
  // Ellipsis animation
  .dot1 {
   animation: visibility 3s linear infinite;
  }
  
  @keyframes visibility {
   0% {
   opacity: 1;
   }
   65% {
   opacity: 1;
   }
   66% {
   opacity: 0;
   }
   100% {
   opacity: 0;
   }
  }
  
  .dot2 {
   animation: visibility2 3s linear infinite;
  }
  
  @keyframes visibility2 {
   0% {
    opacity: 0;
   }
   21% {
    opacity: 0;
   }
   22% {
    opacity: 1;
   }
   65% {
    opacity: 1;
   }
   66% {
    opacity: 0;
   }
   100% {
    opacity: 0;
   }
  }
  
  .dot3 {
   animation: visibility3 3s linear infinite;
  }
  
  @keyframes visibility3 {
   0% {
    opacity: 0;
   }
   43% {
    opacity: 0;
   }
   44% {
    opacity: 1;
   }
   65% {
    opacity: 1;
   }
   66% {
    opacity: 0;
   }
   100% {
    opacity: 0;
   }
  }