.container {
    width: 100%;
    max-width: 710px;
    margin-top: 80px;
    margin-left: auto;
    margin-right: auto;
    padding-right: 100px;
    padding-left: 100px;
    padding-top: 132px;
    background: #faf8f7;
    text-align: center;
    >:last-child {
        margin-bottom: 70px;
    }
    .btnDefault {
        margin-top: 18px;
        margin-bottom: 52px;
    }
    h3 {
        margin-bottom: 17px;
    }
    ul {
        list-style-type: none;
        margin-top: -7px;
        padding: 0;
    }
    li {
        margin-top: -6px;
    }
    
}