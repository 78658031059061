.container {
    width: 100%;
    margin-top: 160px;
    padding-right: 100px;
    padding-left: 100px;
    padding-top: 66px;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    background: #faf8f7;
    h3, h4, p {
        margin-bottom: 0;
    }

    .headerContainer {
        display: flex;
        .col {
            &:first-of-type {
                flex: 2;
            }
            &:nth-of-type(2) {
                flex: 1;
                text-align: right;
            }
        }
        .button {
            margin: 0;
        }
    }

    .cardsHeader {
        display: flex;
        width: 100%;
        margin-top: 60px;
        margin-bottom: 21px;
        
        .cardsHeaderLeft, .cardsHeaderRight {
            flex: 1;
        }
        .cardsHeaderRight {
            text-align: right;
            span {
                &:last-of-type{
                    margin-left: 14px;
                }
            }
        }
    }
    
    .cardsContainer {
        display: flex;
        width: 100%;
        &:last-of-type {
            margin-bottom: 100px;
        }
        .card {
            flex: 1;
            border-top: 1px solid #DDDDD9;
            border-bottom: 1px solid #DDDDD9;
            border-left: 1px solid #DDDDD9;
            background: #FFF;
            &:last-of-type {
                border-right: 1px solid #DDDDD9;
            }
            .cardWrapper {
                
                height: 100%;
                position: relative;
            }
            .cardHeader {
                padding: 15px;
                padding-right: 25px;
                padding-left: 25px;
                border-bottom: 1px solid #DDDDD9;
                
            }
            .cardContent {
                padding: 15px;
                padding-top: 0;
                padding-right: 25px;
                padding-left: 25px;
                display: flex;
                flex-direction: column;
                &.noPadding {
                    padding-right: 0;
                    padding-left: 0;
                    padding: 0;
                }
                .cardContentRow {
                    display: flex;
                    // padding-bottom: 15px;
                    // border-bottom: 1px solid #DDDDD9;
                    margin-bottom: 15px;
                    border-top: 1px solid #DDDDD9;
                    padding-top: 15px;
                    &:first-of-type {
                        border-top: none;
                    }
                    &:last-of-type {
                        border-bottom: none;
                        margin-bottom: 0;
                        padding-bottom: 0;
                        // margin-top: auto;
                        
                    }
                    &.stickToBottom {
                        // padding-bottom: 172px;
                        position: absolute;
                        bottom: 15px;
                        // border-top: 1px solid black;
                        left: 25px;
                        width: calc(100% - 50px);
                        // padding-top: 15px;
                    }
                    .cardContentCol {
                        flex: 1;
                        .materialName {
                            color: #000;
                        }
                        .label {
                            font-size: 26px;
                        }
                        &.right {
                            text-align: right;
                            img {
                                width: 72px;
                            }
                        }
                    }
                }
            }
        }
    }
    
}