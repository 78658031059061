.container {
    width: 100%;
    form {
        height: 48px;
    }
    .inputText {
        width: 100%;
        height: 40px;
        margin-top: 4px;
        padding-left: 18px;
        padding-right: 18px;
        border: none;
        background: none;
        visibility: hidden;
        font-size: 18px;
        font-weight: bold;
        &.visible {
            visibility: visible
        }
    }
    .button {
        
        height: 40px;
        
        // padding-left: 54px;
        padding-left: 18px;
        padding-right: 18px;
        display: block;
        position: relative;
        top: -41px;
        border: none;
        background: none;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: 18px;
        font-weight: bold;
        img {
            width: 20px;
            margin-top: -3px;
            margin-left: 18px;
        }
        &.hidden {
            visibility: hidden;
        }
    }
    &.centered {
        .inputText {
            text-align: center;
        }
        .button {
            text-align: center;
            width: 100%;
            margin: 0 auto;
            left: 19px;
        }
    }
    &.large {
        .inputText {
            font-size: 28px;
        }
        .button {
            font-size: 28px;
        }
    }
}
