.container {
    width: 100%;
    padding-top: 86px;
    h1 {
        margin-top: 40px;
        margin-bottom: 100px;
        font-size: 24px;
        font-weight: bold;
    }
    h2 {
        text-align: center;
        margin-top: 40px;
        margin-bottom: 80px;
    }
    h3 {
        text-align: center;
        margin-bottom: 44px;
    }
}

.btnNewSession {
    margin-bottom: 50px;
    background: #000;
}
.btn {
    background-color: #5C5C5B;
    border-color: #5C5C5B;
    &:hover {
        background-color: #000;
        border-color: #000;
    }
}