.container {
    display: flex;
    flex: 1;
    width: 100%;
    overflow-x: hidden;
}
.btnFullWidthClose {
    position: absolute;
    z-index: 1;
    top: 180px;
    right: 20px;
    &.hidden {
        visibility: hidden;
    }
}
.outputsContainer {
    display: block;
    width: 100%;
    overflow-x: hidden;
    position: relative;
    margin-top: 160px;
    background: #faf8f7;
}
