.container {
    // width: 100%;
    // text-align: center;
    // position: absolute;
    // bottom: 0;
    h3 {
        text-align: left;
    }
    .chartContainer {
        margin-top: 14px;
    }
    .formulaDescription {
        font-size: 12px;
        margin-top: -20px;
    }
}