.container {
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2;
    .top {
        width: 100%;
        height: 80px;
        background-color: #fff;
        border-bottom: 1px solid #DEDFD9;
        .logo {
            height: 40px;
            padding-top: 11px;
            cursor: pointer;
        }

        .navbar {
            top: 12px;
        }
        
        .menu {
            position: absolute;
            right: 5px;
            a {
                padding-top: 22px;
                text-transform: uppercase;
                font-size: 12px;
                letter-spacing: 1px;
                height: 80px;
                padding-top: 32px;

                .menuIcon {
                    width: 34px;
                    position: relative;
                    top: -10px;
                }
                &.active {
                    border-bottom: 2px solid #000;
                    color: rgba(0, 0, 0, 1.0);
                }
            }
            .menuItemMargin {
                margin-right: 20px;
                margin-left: 20px;
            }
            .dropdown {
                left: initial;
                right: 0;
                margin-right: 0;
                margin-left: 0;
                top: 70px;
            }
        }

    }
    .extra {
        width: 100%;
        height: 30px;
        background-color: #5C5C5B;
    }
    
    
}


   
