.container {
    width: 100%;
    // height: 100vh;
    margin-top: 40px;
    margin-bottom: 25px;
    h3 {
        margin-bottom: 20px;
    }
    h5 {
        margin-top: 20px;
        font-weight: normal;
    }
    .productButtons {
        margin-top: 7px;
        Button {
            height: 32px;
            line-height: 32px;
            margin-right: 26px;
            margin-bottom: 9px;
        }
    }
}

.flexContainer {
    display: flex;
    >* {
        flex: 1;
    }
}

.controllerContainer {
    margin-top: 40px;
    padding-right: 5px;
    padding-left: 5px;
    
}



.wallButtonContainer {
    width: 100%;
    display: flex;
    justify-content: space-between;
    position: relative;
    top: 43px;
}
.wallResetButton {
    padding: 20px;
    cursor: pointer;
    margin-top: -8px;
    margin-bottom: -20px;
}
.layerButtonContainer {
    width: 70%;
    margin: 0 auto;
    display: flex;
}
