.container {
    width: 100%;
    h3 {
        margin-bottom: 30px;
    }
    .controllerContainer {
        text-align: center;
        margin-top: 14px;
    }
}

