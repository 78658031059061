.container {
    width: 100%;
    border-top: 2px solid #DDDDD9;
    h3 {
        margin-bottom: 0;
    }
    ul {
        padding-left: 20px;
        
    }
    li {
        margin-bottom: 10px;
    }
    span.accTitle, span.accInfo, .accExpand {
        font-size: 16px;
        color: #5C5C5B;
    }
    &:last-of-type {
        border-bottom: 2px solid #DDDDD9;
    }
    .accordion {
        padding-top: 20px;
        padding-bottom: 20px;
        cursor: pointer;
        display: flex;
        .accTitleWrapper {
            flex: 10;
        }
        .accExpandWrapper {
            flex: 2;
            text-align: right;
        }
    }
    .panel {
        max-height: 0;
        overflow: hidden;
        transition: max-height 0.2s ease-out;
        .content {
            margin-bottom: 4px;
        }
    }
}



