.container {
  // width: 100%;
  // text-align: center;
  // margin-bottom: 5px;
  &:last-of-type {
    // padding-bottom: 200px;
  }
  .label {
    position: relative;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    display: block;
    font-size: 16px;
    &.fullHeight {
      height: 100%;
    }
  }
  
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  
  .checkmark {
    position: absolute;
    top: 7px;
    left: 0;
    height: 24px;
    width: 24px;
    background-color: #FFF;
    border-radius: 50%;
    border: 1px solid #DFE0DA;
  }
  
  :hover input ~ .checkmark {
    border: 1px solid #000;
  }
  
  input:checked ~ .checkmark {
    border: 1px solid #000;
  }
  
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  
  input:checked ~ .checkmark:after {
    display: block;
  }
  
  .checkmark:after {
    top: 7px;
    left: 7px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: #000;
  }
  
  .labelText {
    position: relative;
    left: 36px;
    top: 5px;
    font-weight: normal;
    font-size: 16px; 
    &.hidden {
      visibility: hidden;
    }
  }
  &.fullHeight {
    height: 100%;
    .label {
      height: 100%;
      padding-top: 6px;
    }
    .checkmark {
      top: 11px;
      left: 14px;
    }
    .labelText {
      top: 5px;
      left: 56px;
    }
  }
}

