.container {
    width: 100%;
    margin-top: 80px;
    padding-right: 100px;
    padding-left: 100px;
    padding-top: 66px;
    background: #faf8f7;
    >:last-child {
        margin-bottom: 70px;
    }
    .reportsContainer {
        margin: 15px auto 26px auto;
        .reportRow {
            background: #FFF;
            border-top: 1px solid #DDDDD9;
            border-right: 1px solid #DDDDD9;
            border-left: 1px solid #DDDDD9;
            display: flex;
            &:last-of-type {
                border-bottom: 1px solid #DDDDD9;
            }
            .fieldEditContainer {
                width: 50%;
                max-width: 284px;
                flex: 1;
            }
            .buttons {
                flex: 1;
            }
            .btnPdf,
            .btnErase,
            .btnOpen {
                float: right;
                margin-left: 16px;
                margin-right: 16px;
            }
            .btnOpen {
                margin-left: 36px;
                margin-right: 25px;
            }
        }
    }
}