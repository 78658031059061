.container {
    width: 100%;
    border-top: 1px solid #B0B0B0;
    // margin-top: 100px;
    transition: background-color 0.4s;
    p.description {
        // font-size: 14px;
        // text-transform: uppercase;
    }
    h3 {
        margin-top: 45px;
        margin-bottom: 20px;
    }
    h4 {
        margin-top: 45px;
    }
    h5 {
        margin-top: 34px;
    }
    ul {
        list-style-type: none;
        padding: 0;
    }
    span.accTitle, span.accInfo, .accExpand {
        font-size: 16px;
        color: #5C5C5B;
    }
    span.accTitle, span.accExpand {
        font-weight: bold;
    }
    &:first-of-type {
        margin-top: 80px;
    }
    &:last-of-type {
        border-bottom: 1px solid #B0B0B0;
    }
    &.active {
        background-color: #D1E1E6;
        span.accTitle, span.accInfo, .accExpand {
            // color: #8DB5C0;
        }
    }
}

.accordion {
    padding-top: 20px;
    padding-bottom: 20px;
    cursor: pointer;
}
.panel {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.2s ease-out;
    .sectionHeader {
        // border-bottom: 1px solid #D7D6D5;
    }
    .row {
        padding: 40px;
        &:nth-child(odd) {
            background-color: #faf8f7;
        }
        
    }
}
.edit {
    cursor: pointer;
}
.editIcon {
    margin-top: -7px;
    margin-left: 14px;
}
.btn {
    background-color: #5C5C5B;
    border-color: #5C5C5B;
    &:hover {
        background-color: #000;
        border-color: #000;
    }
    &.erase,
    &.activate {
        margin-top: 0px;
        margin-right: 10px;
        margin-bottom: 40px;
        margin-left: 10px;  
    }
    &.erase {
        background-color: #C94545;
    }
    
}

