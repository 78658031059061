.container {
    width: 100%;
    height: 100%;
    padding-top: 80px;
    background-color: #fff;
    .imgContainer {
        width: 100%;
        height: 100%;
        background-image: url('./Blush_3241_Svensson_V24_3.jpg');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        opacity: 0;
        transition: opacity 0.8s;
        &.show {
            opacity: 1.0;
        }
    }
    .content {
        width: 50%;
        position: absolute;
        bottom: 56px;
        margin-left: 52px;
        width: 384px;
        @media only screen and (min-width: 768px) {
            width: 600px;
        }
        @media only screen and (min-width: 1024px) {
            width: 600px;
        }
        @media only screen and (min-width: 1200px) {
            width: 600px;
        }
    }
    h1 {
        font-size: 50px;
        font-weight: bold;
        color: #fff;
        margin-bottom: 16px;
        @media only screen and (min-width: 768px) {
            // font-size: 50px;
        }
        @media only screen and (min-width: 1024px) {
            // font-size: 60px;
        }
        @media only screen and (min-width: 1200px) {
            // font-size: 80px;
        }
    }
}
