.container {
    opacity: 0.0;
    transition: opacity 0.4s;
    display: flex;
    flex-direction: column;
    &.mounted {
        opacity: 1.0;
    }

    .barContainer {
        width: 100%;
        text-align: right;
    }
    .barBottom {
        width: 100%;
        height: 10px;
        background-color: #B3C9C4;
    }
    .bar {
        height: 10px;
        background-color: #898A84;
        position: relative;
        top: -10px;
        transition: width 0.8s;
    }
    .labelContainer {
        display: flex;
        .label {
            flex: 1;
            position: relative;
            top: -4px;
            text-align: left;
            font-size: 15px;
            font-weight: normal;
            color: #7f7e7a;
            &.labelRight {
                text-align: right;
                color: #679B8F;
            }
        }
    }
    .description {
        position: relative;
        top: -20px;
        @media only screen and (min-width: 992px) {
            top: -38px;
        }
    }
    &.highlightLeft {
        .barBottom {
            background-color: #898A84;
        }
        .bar {
            background-color: #B3C9C4;
        }
        .labelContainer {
            .label {
                color: #679B8F;
                &.labelRight {
                    color: #7f7e7a;
                    font-size: 16px;
                    margin-top: 11px;
                }
            }
        }
    }
    &.labelTop {
        .labelContainer {
            order: -1;
            margin-bottom: 4px;
        }
    }

    @media only screen and (min-width: 1024px) {
        .labelContainer {
            .label{
                font-size: 26px;
            }
        }
    }

}