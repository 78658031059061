.container {
    display: flex;
    width: 100%;
    height: 80px;
    background-color: #faf8f7;
    position: absolute;
    top: 80px;
    padding-top: 2px;
    border-bottom: 1px solid #DEDFD9;

    .leftsideButtons {
        flex: 1;
        position: relative;
        top: 13px;
        left: 32px;
    }
    .rightsideButtons {
        flex: 1;
        position: relative;
        top: 13px;
        right: 32px;
        text-align: right;
        Button {
            margin-right: 10px;
            position: relative;
            top: 2px;
            &:last-of-type {
                margin-right: 0;
                top: 0;
            }
        }
    }
}
